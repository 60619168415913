.form_title {
    display: none;
}

.form {
    width: 100%;
}

.input_wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.form_input {
    display: block;
    width: 90%;
    height: 35px;
    margin: 0px auto 10px;
    padding: 5px 10px;
    font: 11px "Roboto", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 1px solid #EAEAEA;
    border-radius: 2px;
    outline: none;
    color: #555555;
}

textarea.form_input {
    height: 200px;
    padding: 10px;
    resize: none;
    margin-bottom: 20px;
}

button.form_input {
    color: #FFFFFF;
    background-color: rgba(17, 17, 17, 0.8);
    cursor: pointer;
    margin-bottom: 30px;
}

button.form_input:hover {
    background-color: rgb(17, 17, 17);
}

.map {

}