.footer_wrapper {
	width: 100%;
	background-color: rgba(10, 10, 10, 0.9);
	padding: 30px 0px;
}

.footer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90%;
	max-width: 1400px;
	z-index: 100;
}

.footer_company_name {
	margin-bottom: 20px;
	letter-spacing: 4px;
	font-weight: 400;
	font-size: 30px;
	text-decoration: none;
	text-transform: uppercase;
	color: #FFFFFF;
}

.languages {
	margin-bottom: 20px;
	cursor: pointer;
}

.language {
	color: #FFFFFF;
}

.active_language {
	color: var(--color-text);
}

.spacer {
	padding: 0px 10px;
	color: #FFFFFF;
}

.footer_contacts {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.footer_contact {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	text-decoration: none;
	color: #FFFFFF;
	margin: 0px 20px;
}

.footer_company_name:hover,
.language:hover,
.footer_contact:hover {
	color: var(--color-text);
}

.social_networks {
	display: inherit;
}

.footer_facebook,
.footer_instagram {
	width: 40px;
	height: 40px;
	background-size: cover;
}

.footer_facebook {
	background-image: url('../files/images/facebook_icon.png');
}

.footer_facebook:hover {
	background-image: url('../files/images/facebook_icon_hover.png');
}

.footer_instagram {
	background-image: url('../files/images/instagram_icon.png');
}

.footer_instagram:hover {
	background-image: url('../files/images/instagram_icon_hover.png');
}

.footer_phone,
.footer_email {
	min-width: 160px;
}

.footer_text {
	font-size: 12px;
	color: #FFFFFF;
	text-align: center;
}

@media (max-width: 650px) {
    .social_networks {
        order: 1;
        width: 100%;
        margin-bottom: 20px;
        justify-content: center;
    }

    .footer_phone {
    	order: 2;
    }

    .footer_email {
    	order: 3;
    }
}
