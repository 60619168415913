.about_us_wrapper {
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    max-width: 1440px;
    margin: 0px auto 100px auto;
}

.about_us_text {
	display: flex;
	flex-wrap: wrap;
    justify-content: space-between;
	flex-direction: row;
    width: 95%;
    margin: 0px auto;
    color: var(--light-gray-text);
}

.about_us_text span {
    display: block;
    margin-bottom: 20px;
    width: 30%;
    min-width: 320px;
    text-align: justify;
}


@media (max-width: 1150px) {
    .about_us_text span {
        width: 45%;
    }
}

@media (max-width: 800px) {
    .about_us_text span {
        width: 95%;
    }
}