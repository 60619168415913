.form_wrapper {
	display: flex;
    position: fixed;
    flex-direction: column;
    flex-wrap: wrap;
    width: 80%;
    max-width: 500px;
    margin: 0px auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 20px;
    z-index: 1000;
    background-color: #FFFFFF;
    transition: all 0.4s ease-in-out 0s;
}

.form_title {
    font: 30px "Roboto", sans-serif;
    text-align: center;
    margin: 30px auto 30px;
}

.form {
    width: 90%;
}

.input_wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.form_input {
    display: block;
    width: 80%;
    height: 35px;
    margin: 0px auto 10px;
    padding: 5px 10px;
    font: 11px "Roboto", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 1px solid #EAEAEA;
    border-radius: 2px;
    outline: none;
    color: #555555;
}

textarea.form_input {
    height: 250px;
    padding: 10px;
    resize: none;
    margin-bottom: 20px;
}

button.form_input {
    color: #FFFFFF;
    background-color: rgba(17, 17, 17, 0.8);
    cursor: pointer;
    margin-bottom: 30px;
}

button.form_input:hover {
    background-color: rgb(17, 17, 17);
}