.homepage_main_image_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
    margin-bottom: 100px;
    background-image: url("../files/images/HomePage/HomePage.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 0px 0px 0px 300px;
    z-index: -1;
}

.homepage_main_image_data {
    position: relative;
    z-index: 100;
	max-width: 90%;
}

.homepage_main_image_overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0px 0px 0px 300px;
}