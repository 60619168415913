.contacts_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	max-width: 1440px;
	margin: 0px auto 100px auto;
	background-color: rgba(10, 10, 10, 0.9);;
	border-radius: 70px;
	text-align: center;
}

.contacts_title {
	margin: 100px 0px 10px 0px;
	font-size: 3vw;
	line-height: 50px;
	color: #ffffff;
	max-width: 90%;
}

.contacts_description {
	margin-bottom: 30px;
	font-size: 1.5vw;
	color: #ffffff;
	max-width: 90%;
}

.contacts_button {
	padding: 20px 40px;
	margin-bottom: 100px;
	cursor: pointer;
	background: var(--color-text);
	border: 2px solid var(--color-text);
	border-radius: 20px;
	font-size: 20px;
}

.contacts_button:hover {
	background-color: #FFFFFF;
	color: #000000;
	border-color: #FFFFFF;
}

@media (max-width: 850px) {
	.contacts_title {
		font-size: 25.5px;
	}

	.contacts_description {
		font-size: 12.5px;
	}
}